
import { DataService } from "./dataService";

// 1001

export const postLogin = async (values) => {
  return await DataService.post('/auth/admin/login', {
    email: values.email,
    password: values.password,
  });
}

export const getLogout = async () => {
  return await DataService.get('/auth/logout', {

  });
}

export const checkResetKey = async (token) => {
  let url = '/auth/reset/';
  if (token !== null && token !== undefined) {
    url += `${token}`;
  }
  return await DataService.get(url)
}

export const resetPassword = async (data) => {
  return await DataService.post(`/auth/reset/${data.token}`, {
    password: data.password
  });
}


// get users

export const getUsers = async (data) => {
  let url = '/users/usersList';

  if (data.pageNumber) {
    url += `?page=${data.pageNumber}`;
  }
  if (data.startDate !== null) {
    url += `&startDate=${data.startDate}`;
  }
  if (data.endDate !== null) {
    url += `&endDate=${data.endDate}`;
  }
  if (data.search !== null) {
    // Assuming 'search' is a string, you might need to encode it
    const encodedSearch = encodeURIComponent(data.search);
    url += `&search=${encodedSearch}`;
  }
  if (data.country !== null) {
    url += `&country=${data.country}`;
  }
  return await DataService.get(url)
}

export const getUser = async (data) => {
  let url = '/users/user';

  if (data.id !== null && data.id !== undefined) {
    url += `/${data.id}`;
  }

  return await DataService.get(url)
}

export const getLearning = async (data) => {
  let url = '/learn-history/user/list';
  if (data.id !== null && data.id !== undefined) {
    url += `/${data.id}`;
  }
  return await DataService.get(url)
}

export const getLearnHistory = async (data) => {
  let url = '/learn-history/list';
  if (data.id !== null && data.id !== undefined) {
    url += `/${data.id}`;
  }
  return await DataService.get(url)
}

export const getDailyLearning = async (data) => {
  let url = '/dialogue/list';
  if (data.id !== null && data.id !== undefined) {
    url += `/${data.id}`;
  }
  if (data.startDate !== null && data.startDate !== undefined) {
    url += `?startDate=${data.startDate}`;
  }
  if (data.endDate !== null && data.endDate !== undefined) {
    if (data.startDate !== null && data.startDate !== undefined) {
      url += `&endDate=${data.endDate}`;
    }
    else {

      url += `?endDate=${data.endDate}`;
    }
  }
  return await DataService.get(url)
}

export const getCourse = async (data) => {
  let url = '/course/list';

  if (data.pageNumber) {
    url += `?page=${data.pageNumber}`;
  }
  if (data.categoryId !== null) {
    url += `&categoryId=${data.categoryId}`;
  }
  if (data.isUse !== null) {
    url += `&isUse=${data.isUse}`;
  }
  if (data.courseName !== null) {
    // Assuming 'search' is a string, you might need to encode it
    const encodedSearch = encodeURIComponent(data.courseName);
    url += `&courseName=${encodedSearch}`;
  }
  return await DataService.get(url)
}

export const getPackage = async (data) => {
  let url = '/package/list';

  if (data.pageNumber) {
    url += `?page=${data.pageNumber}`;
  }
  if (data.isUse !== null) {
    url += `&isUse=${data.isUse}`;
  }
  if (data.search !== null) {
    if (data.searchType === "package") {
      const encodedSearch = encodeURIComponent(data.search);
      url += `&packageName=${encodedSearch}`;
    }
    else if (data.searchType === "course") {
      const encodedSearch = encodeURIComponent(data.search);
      url += `&courseName=${encodedSearch}`;
    }
  }
  return await DataService.get(url)
}

export const getCategory = async () => {
  return await DataService.get("/category/list")
}



export const getPassManagement = async (data) => {
  let url = `/order/pass-management`;
  if (data.id !== null && data.id !== undefined) {
    url += `/${data.id}`;
  }
  return await DataService.get(url)
}

export const getWordSentence = async (data) => {
  let url = '/word-sentence/count';
  if (data.id !== null && data.id !== undefined) {
    url += `/${data.id}`;
  }
  return await DataService.get(url)
}








