import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getLogout, postLogin } from '../../config/dataService/apiList';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await postLogin(values);
      if (response.status === 200) {
        dispatch(loginSuccess(true, response?.data.user));
        callback();
      } else {
        dispatch(loginErr(data.message));
      }
    } catch (err) {
      console.error('------', err);
      dispatch(loginErr(err?.message || JSON.stringify(err) || 'Network error'));
    }
  };
};

const register = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/register', values);
      if (response.data.errors) {
        dispatch(loginErr('Registration failed!'));
      } else {
        dispatch(loginSuccess(false));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      getLogout();
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

const setLogged = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get('/users/current-user');
      dispatch(loginSuccess(response?.data.user));
    } catch (err) {
      dispatch(loginSuccess(false));
    }
  };
};

export { login, logOut, register, setLogged };
